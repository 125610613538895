<template src="./location.htm">
 


</template>

<script>

import { mapState } from 'vuex';
import Footer from '@/layouts/footer/Footer';
import SubHeader from '@/layouts/sub_header/Subheader.vue';
export default {
  name: "location",
  components: {

      'footer-component': Footer,
      'sub-header': SubHeader,
  },
  data: () => ({
    valid: true,
    name: '',
    selected: [],
    dialog: false,
    dialog2: false,
    view_table: true,
    view_info: false,
    overlay: true,
    is_admin: '',
    ids: [],
    Serial_No_Rules: [ v => !!v || 'Serial Number is required',],      
    Location_Name: '',
    Location_Name_Rules: [
          v => !!v || 'Location Name is required',
        ],
  IdentificationRules: [
        v => !!v || 'This Field is Required',
      ],
  table_header: [
          { 
            text: 'Location',
            align: 'start',
            sortable: false,
            value: 'location_name',
          },
          { text: '', value: 'action' },
          
        ],
  locations: [],
  initForm: {
    id: '',
    location: '',
  },
  editMode: false,
  userprofile: [
      { title: "My Profile" },
      { title: "My Balance" },
      { title: "Inbox" },
      { title: "Account Setting" },
      { title: "Logout" }
    ]
  }),
  computed: {
    ...mapState({
        user: state => state.auth.user,
        isAuthenticated: state => state.auth.isAuthenticated,
    }),
  },
  created(){
      this.overlay = true;
  },
  watch: {
      dialog (val) {
        val || false
      },
    },

  methods: {
      SummaryView (val) {
        val,this.dialog2 = true
      },
      closeDialog () {
          this.$refs.form.reset(),
          this.dialog=!this.dialog
      },
      AddForm (){
        this.editMode = false;
      },
      edit(data) {
        this.editMode = true;
        this.dialog = true;

        this.initForm.location = data.location_name;
        this.initForm.id = data.id;
      }, 
      search() {
        this.overlay = true;

        this.$api
          .get('/location')
            .then(res => {
                this.locations = res.data.data;
                this.overlay = false;
            })
            .catch(err => {
                this.overlay = false;
                this.$toast.open({
                    message:  err.response.data.msg,
                    type: 'error',
                    position: 'bottom'
                })
            })
      },
      save() {
        if(this.$refs.form.validate()) {

          this.$api
            .put(`/location/${this.initForm.id}`, this.initForm)
            .then(res => {
                this.$toast.open({
                    message:  'Successfully Saved!',
                    type: 'success',
                    position: 'bottom'
                })
              this.closeDialog();
              this.search();
            })
            .catch(err => {
                this.$toast.open({
                    message:  err.response.data.msg.message,
                    type: 'error',
                    position: 'bottom'
                })
            })
        }
      },
      delete_selected (){
        var result = confirm("Are you sure you want to delete?");
        if (result==true) {
          this.ids = this.selected.map(loc => {
            return loc.id;
          });

          this.$api
            .post('/location/delete', {ids: this.ids})
            .then(res => {
                this.$toast.open({
                    message:  res.data.msg,
                    type: 'success',
                    position: 'bottom'

                }),
                this.search();
            })
            .catch(err => {
                this.$toast.open({
                    message:  err.response.data.msg,
                    type: 'error',
                    position: 'bottom'
                })
            })
        }
      },
      submit () {
        if(this.$refs.form.validate()){
          
          this.$api
            .post(`/location`, this.initForm)
            .then(res => {
                this.$toast.open({
                    message:  'Successfully Saved!',
                    type: 'success',
                    position: 'bottom'
                })
                this.closeDialog();
                this.search();
            })
            .catch(err => {
                this.dialog = false;
                this.$toast.open({
                    message:  err.response.data.msg.message,
                    type: 'error',
                    position: 'bottom'
                })
            });
        }
      },
    },
  mounted() {
    this.search();

    this.name = this.user.name;
    this.is_admin = this.user.is_admin;
    if (!this.isAuthenticated) {
      this.$router.replace({ name: 'auth.login' })
    }
  }
};
</script>